<template>
  <div class="pt-15vh px-5vh">
    <ht-card>
      <div id="slide-birthday">
        <div class="place-self-center">
          <ht-personal-avatar
              :image="data.image"
              :name="data.title"
              :message="data.description"/>

          <div class="text-center">
            <p class="text-5xl font-700 mb-0">{{ $t('birthday_cheer', {name: Name}) }}</p>
            <div class="text-3xl" :style="TextStyle" v-html="Message"></div>
            <div class="text-2xl" :style="TextStyle" v-html="Function"></div>
          </div>
        </div>

        <div id="balloon-1" class="balloon">
          <inline-svg :src="require('@/assets/images/balloons.svg')"/>
        </div>
        <div id="balloon-2" class="balloon">
          <inline-svg :src="require('@/assets/images/balloons.svg')"/>
        </div>
        <div id="balloon-3" class="balloon">
          <inline-svg :src="require('@/assets/images/balloons.svg')"/>
        </div>
        <div id="cake-1" class="cake">
          <inline-svg :src="require('@/assets/images/cake.svg')"/>
        </div>
      </div>

    </ht-card>
  </div>
</template>

<script>
import HtCard from "@/components/Elements/Card/HtCard";
import HtPersonalAvatar from "@/components/Elements/Card/HtPersonalAvatar";

import InlineSvg from "vue-inline-svg";

export default {
  name: "SlideBirthday",
  components: {
    HtCard,
    HtPersonalAvatar,
    InlineSvg
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    play: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    Name() {
      return this.data.title
    },
    Message() {
      return this.data.description
    },
    Function() {
      if(this.data.profile) {
        return this.data.profile.function;
      }
      return '';
    },
    TextStyle() {
      return {
        'color': this.$store.getters.getHexColor,
        'text-shadow': '0 2px 4px rgba(0,0,0,0.10)'
      }
    }
  },
  methods: {
    setSVGColor() {
      const element = document.querySelector('#color-filter feColorMatrix')
      element.setAttribute('values', this.$store.getters.getColorMatrix)
    }
  },
  mounted() {
    this.setSVGColor()
  }
}
</script>

<style lang="scss" scoped>

#slide-birthday {
  display: grid;
  height: 100%;
  width: 100%;
  position: relative;
}

#balloon-1, #balloon-2, #balloon-3 {
  position: absolute;
}

#balloon-1 {
  top: -2vw;
  left: -3vw;
  height: 0;
  width: 9vw;
}

#balloon-2 {
  height: 18vw;
  width: 16vw;
  top: 7vw;
  left: 7vw;
}

#balloon-3 {
  height: 18vw;
  width: 16vw;
  top: -1vw;
  right: -7vw;
}

#cake-1 {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 18vw;
  width: 15vw;
}
</style>
