<template>
  <div class="grid w-full" style="grid-template-columns: min-content 1fr">
    <section class="grid" style="grid-template-columns: min-content min-content">
      <ht-likes :amount="likes" v-show="HasLikes"/>
      <ht-comments :amount="comments" v-show="HasComments"/>
    </section>
    <section class="justify-self-end">
      <div class="flex items-center mt-1 mr-4 space-x-4">
        <div class="self-end mr-2 w-2.75vh">
          <inline-svg :src="require('@/assets/images/hitribe_icon.svg')"/>
        </div>
       <div class="self-end ">
         <span class="text-xl whitespace-nowrap text-HiTribeBlue font-400">{{ $t('read_more') }}</span>
       </div>
      </div>
    </section>
  </div>
</template>

<script>
import HtLikes from "@/components/Elements/HtLikes";
import HtComments from "@/components/Elements/HtComments";
import InlineSvg from 'vue-inline-svg'

export default {
  name: "HtCardFooter",
  components: {
    HtLikes,
    HtComments,
    InlineSvg
  },
  props: {
    likes: {
      type: Number,
      required: true
    },
    comments: {
      type: Number,
      required: true
    },
    HasLikes:{
      type: Boolean,
      required: false,
      default: true
    },
    HasComments:{
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>
