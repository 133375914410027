<template>
  <div class="pt-15vh px-5vh">
    <ht-card>
      <div class="flex items-center h-full">
        <youtube
            :id="randomId"
            :video-id="Video"
            :resize="true"
            :fitParent="true"
            nocookie
            ref="youtube"
            @ready="ready"
            @error="handleError"
            @ended="videoEnded"
            class="h-80vh"
            style="width: calc(100vw - 10vh)"
            :player-vars="playerVars"
        />
      </div>
    </ht-card>


  </div>
</template>

<script>
import HtCard from "@/components/Elements/Card/HtCard";

export default {
  name: "SlideYoutubevideo",
  components: {
    HtCard
  },

  props: {
    play: {
      type: Boolean,
      default: false
    },
    activeSlide: {
      type: Number,
      required: true
    },
    slideId: {
      type: Number,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },

  watch: {
    Play: {
      handler: function (value) {
        if (value) {
          this.playVideo()
        }
      }
    }
  },

  data() {
    return {
      video: '',
      playerReady: false,
      randomId: Math.floor(Math.random() * 999999999999 + 1),
      playerVars: {
        mute: 1
      }
    }
  },

  methods: {
    playVideo() {
      if (this.activeSlide === this.slideId) {
        if (this.playerReady) {

          this.$nextTick(function () {
            this.player.playVideo()
          })

        }
      }
    },

    handleError(error) {
      console.log(error)
      this.videoEnded()
    },

    videoEnded() {
      this.$emit('ended')
    },

    ready() {
      this.playerReady = true
      this.playVideo()
    }

  },
  computed: {
    Play() {
      return this.$store.getters.getPlay
    },

    player() {
      return this.$refs.youtube.player
    },

    Video() {
      return this.video
    }
  },

  created() {
    this.video = this.data.video
  }
}
</script>

<style scoped>

</style>
