<template>
  <div class="flex items-center mr-4 space-x-4">
    <div class="w-3.75vh h-3.75vh">
      <img :src="require('@/assets/images/likes.png')" alt="" />
    </div>
    <div class="text-4xl text-gray font-600">{{ amount }}</div>
  </div>
</template>

<script>
export default {
  name: "HtLikes",
  props: {
    amount: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped></style>
