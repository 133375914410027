<template>
  <div class="pt-15vh px-5vh">
    <ht-card>
      <div id="slide-feed">
        <section id="content-container">
          <ht-nav-title :title="$t('update')" v-once/>

          <ht-card-title :title="Title" v-once/>

          <div id="description" class="flex-1" v-html="Description" v-once></div>

          <div class="flex-none">
            <ht-card-footer :likes="LikesCount" :comments="CommentsCount" v-once/>
          </div>
        </section>

        <section>
          <ht-image-container
              :images="Images"
              :play="Play"
              :duration="duration"
          />
        </section>
      </div>
    </ht-card>
  </div>
</template>


<script>
import HtCard from "@/components/Elements/Card/HtCard";
import HtNavTitle from "@/components/Elements/Card/HtNavTitle";
import HtImageContainer from "@/components/Elements/HtImageContainer";
import HtCardTitle from "@/components/Elements/Card/HtCardTitle";

import * as Mixins from '@/mixins/mixins'
import HtCardFooter from "@/components/Elements/Card/HtCardFooter";

export default {
  name: "SlideFeed",
  components: {
    HtCard,
    HtCardFooter,
    HtNavTitle,
    HtImageContainer,
    HtCardTitle
  },

  mixins: [
    Mixins.CommentsCountMixin,
    Mixins.LikesCountMixin,
    Mixins.ImagesMixin,
    Mixins.SlidePropsMixin
  ],

  computed: {
    Play() {
      return this.$store.getters.getPlay && this.activeSlide === this.slideId
    },
    Title() {
      return this.data.title ?? ''
    },
    Description() {
      return this.data.description ?? ''
    }
  }
}
</script>

<style lang="scss" scoped>
#slide-feed {
  display: grid;
  grid-template-columns: 55% 45%;
  height: 100%;
  gap: 1vh;
}

#description {
  overflow: hidden;
  position: relative;

  &:after {
    content: " ";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $fade-height;
    background: rgb(255, 255, 255);
    background: $fade-gradient;
  }
}

#content-container {
  padding: $card-padding;
  display: flex;
  flex-direction: column;
  height: $content-container-height;
}
</style>
