<template>
  <div id="images-container">
    <div id="image-decoration-1" :style="ImageDecoration1Style"/>
    <div id="image-decoration-2" :style="ImageDecoration2Style"/>
    <div id="image-decoration-3" :style="Imagedecoration3Style"/>
    <div id="image">

      <div id="image-container" ref="image-container" class="flex transition duration-1000" :class="{'play': play}">
        <img :key="image.key" v-for="image in formattedImages" :id="`image_${image.key}`"
             :src="image.path" alt=""
             class="h-full w-min"
        >
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "HtImageContainer",

  components: {},

  props: {
    images: {
      type: Array,
      required: true,
      default: () => []
    },

    play: {
      type: Boolean,
      required: false,
      default: false
    },

    duration: {
      type: Number,
      default: 60
    }
  },

  watch: {
    play: {
      handler: function (val) {
        if (val) {
          const container = this.$refs["image-container"]
          let scrollWidth = 0

          container.childNodes.forEach((node) => {
            scrollWidth += node.clientWidth
          })

          const oneVisualWidth = window.innerWidth / 100;

          let calculatedWidth = scrollWidth - ((oneVisualWidth * 42) - (oneVisualWidth * 3.8));

          if (calculatedWidth > 0) {
            calculatedWidth = -calculatedWidth
          } else {
            calculatedWidth = 0
          }

          container.style.transition = `transform ${(this.duration - 4)}s linear 2s`
          container.style.transform = `translateX(${calculatedWidth}px)`
        }
      }
    }
  }
  ,
  data() {
    return {
      imageIndex: 0,
      imagesSet: false,
      activeImage: null,
      nextImage: null,
      formattedImages: [],
      FADE_TIME: 1,
      DELAY: 0.10,
      animateImage: true

    }
  }
  ,
  computed: {
    RgbColors() {
      return this.$store.getters.getRGBColors
    }
    ,
    ImageDecoration1Style() {
      return {
        'background-color': `rgba(${this.RgbColors.red},${this.RgbColors.green},${this.RgbColors.blue}, .1)`
      }
    }
    ,
    ImageDecoration2Style() {
      return {
        'background-color': `rgba(${this.RgbColors.red},${this.RgbColors.green},${this.RgbColors.blue}, .4)`
      }
    }
    ,
    Imagedecoration3Style() {
      return {
        'background-color': `rgba(${this.RgbColors.red},${this.RgbColors.green},${this.RgbColors.blue}, 1)`
      }
    }
  }
  ,
  methods: {
    /** Give all images a unique key */
    formatImages() {
      this.formattedImages = this.images.map((image) => {
        return {
          key: Math.random(),
          path: image
        }
      })
    }
  }
  ,
  mounted() {
    this.formatImages()
    this.imageIndex = 0
  }
}
</script>

<style lang="scss" scoped>
#images-container {
  position: relative;
  height: 100%;

  #image-decoration-1,
  #image-decoration-2,
  #image-decoration-3 {
    width: 100%;
    height: 100%;
    border-radius: 3vw 10px 10px 3vw;
    position: absolute;
    top: 0;
    right: 0;
  }

  #image-decoration-2 {
    width: calc(100% - 1.8vw);
  }

  #image-decoration-3 {
    width: calc(100% - 3vw);
  }

  #image {
    width: calc(100% - 3.8vw);
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border-radius: 3vw 10px 10px 3vw;

    div {
      width: 100%;
      height: 100%;
    }

    #image-container {
      position: relative;
      left: 0;
    }

    img {
      object-fit: initial;
      max-width: initial;
    }
  }
}
</style>
