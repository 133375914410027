<template>
  <div class="avatar" :class="{'has-more': hasMore}">
    <img :src="avatar" alt="">

    <div class="cover">
      <div class="amount text-2xl" v-if="hasMore">+{{ amount - 4 }}</div>
    </div>

  </div>
</template>

<script>
export default {
  name: "HtAvatar",
  props: {
    avatar: {
      type: String,
      required: true
    },
    hasMore: {
      type: Boolean,
      required: false,
      default: false
    },
    amount: {
      type: Number,
      required: false,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  background-color: white;
  border-radius: 999999px;
  width: 8vh;
  height: 8vh;
  position: relative;

  img {
    width: 7vh;
    height: 7vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 99999px;
    object-fit: cover;
  }

  .cover{
    display: none;
  }

  &.has-more {

    .cover{
      display: block;
      position: absolute;
      background-color: rgba(31, 38, 41, 0.8);
      border-radius: 999999px;
      width: 8vh;
      height: 8vh;
      z-index: 90;
    }


    .amount {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
    }
  }

}
</style>
