<template>
  <div class="date">{{ FormattedTime }}</div>
</template>

<script>
import dayjs from "@/dayjs";
import {EVERY_SECOND} from "@/helpers/helpers";
import {mapGetters} from 'vuex'

export default {
  name: "HtClock",
  data() {
    return {
      time: ''
    }
  },
  watch: {
    Time: function (updated_time) {
      this.time = dayjs.unix(updated_time)
    }
  },

  computed: {
    ...mapGetters({
      Time: 'getTime'
    }),

    FormattedTime() {
      return this.time.tz("Europe/Brussels").format('ddd D MMM HH:mm')
    }
  },

  created() {
    this.time = dayjs.unix(this.Time)

    setInterval(() => {
      this.time = this.time.add(1, 'seconds')
    }, EVERY_SECOND)

  }
}
</script>

<style lang="scss" scoped>
.date {
  @apply whitespace-nowrap;
  @apply text-4xl;
  @apply text-gray;
  @apply font-600;
}
</style>
