<template>
  <div class="pt-15vh px-5vh">
    <ht-card>
      <div id="slide-spotlight">
        <section>
          <ht-image-container-left
            :images="Images"
            :play="Play"
            :duration="duration"
          />
        </section>

        <section
        id="content-container"
          class="flex flex-col justify-between w-full h-full"
        >
          <div></div>

          <div>
            <div id="nav-title">
              <ht-nav-title :title="$t('spotlight')" v-once />
            </div>
            <div class="title">
              <ht-card-title :title="Title" v-once />
            </div>
            <div id="description">
              <div v-html="Description" v-once></div>
            </div>
          </div>

          <div class="footer">
            <ht-card-footer :likes="LikesCount" :has-comments="false" v-once />
          </div>
        </section>
      </div>
    </ht-card>
  </div>
</template>

<script>
import HtCard from "@/components/Elements/Card/HtCard";
import HtNavTitle from "@/components/Elements/Card/HtNavTitle";
import HtImageContainerLeft from "@/components/Elements/HtImageContainerLeft";
import HtCardTitle from "@/components/Elements/Card/HtCardTitle";

import * as Mixins from "@/mixins/mixins";
import HtCardFooter from "@/components/Elements/Card/HtCardFooter";

export default {
  name: "SlideFeed",
  components: {
    HtCard,
    HtCardFooter,
    HtNavTitle,
    HtImageContainerLeft,
    HtCardTitle,
  },

  mixins: [
    Mixins.CommentsCountMixin,
    Mixins.LikesCountMixin,
    Mixins.ImagesMixin,
    Mixins.SlidePropsMixin,
  ],

  computed: {
    Play() {
      return this.$store.getters.getPlay && this.activeSlide === this.slideId;
    },
    Title() {
      if (this.data.profile) {
        return this.data.profile.first_name + " " + this.data.profile.last_name;
      }
      return "";
    },
    Description() {
      return this.data.message ?? "";
    },
  },
};
</script>

<style lang="scss" scoped>
#slide-spotlight {
  display: grid;
  grid-template-columns: 55% 45%;
  height: 100%;
  gap: 1vh;
}


#content-container {
  padding: $card-padding;
}
</style>
