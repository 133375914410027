<template>
  <div id="event-side-bar" class="grid grid-rows-2 h-full">
    <div class="text-center">
      <div class="text-gray uppercase text-3xl font-600">{{ EventWeekDay }}</div>
      <div class="font-600 text-7xl" :style="NumberStyle">{{ EventDay }}</div>
      <div class="text-gray uppercase text-3xl font-600">{{ EventMonth }}</div>
    </div>
    <div class="relative">
      <div class="flex justify-center avatar-4" :id="`avatar-${Avatars[3].id}`" v-if="Avatars.length > 3">
        <ht-avatar
            :has-more="HasMoreThen4Participants"
            :amount="participants.length"
            :avatar="Avatars[3].image"
        />
      </div>
      <div class="flex justify-center avatar-3" :id="`avatar-${Avatars[2].id}`" v-if="Avatars.length > 2">
        <ht-avatar
            :avatar="Avatars[2].image"/>
      </div>
      <div class="flex justify-center avatar-2" :id="`avatar-${Avatars[1].id}`" v-if="Avatars.length > 1">
        <ht-avatar
            :avatar="Avatars[1].image"/>
      </div>
      <div class="flex justify-center avatar-1" :id="`avatar-${Avatars[0].id}`" v-if="Avatars.length > 0">
        <ht-avatar
            :avatar="Avatars[0].image"/>
      </div>

    </div>
  </div>
</template>

<script>
import HtAvatar from "@/components/Elements/Card/HtAvatar";
import dayjs from "dayjs";

export default {
  name: "EventSidebar",
  components: {
    HtAvatar
  },
  props: {
    date: {
      type: Number,
      required: true
    },
    participants: {
      type: Array,
      required: false,
      default: () => []
    },
  },
  computed: {
    Day() {
      return dayjs.unix(this.date)
    },
    EventWeekDay() {
      return this.Day.format('ddd')
    },
    EventDay() {
      return this.Day.format('D')
    },
    EventMonth() {
      return this.Day.format('MMM')
    },
    NumberStyle() {
      return {
        color: this.$store.getters.getHexColor
      }
    },
    HasMoreThen4Participants() {
      return this.participants.length > 4
    },
    Avatars() {
      const avatars = []
      if (this.participants.length > 0) {
        for (let x = 0; x < 4; x++) {

          if (this.participants[x] !== undefined) {
            avatars.push(
                {
                  id: Math.floor((Math.random() * 100) + 1),
                  image: this.participants[x].image_thumb
                })
          }
        }
      }
      return avatars
    }
  }
}
</script>

<style lang="scss" scoped>
#event-side-bar {
  border-right: 3px solid rgba(234, 237, 243, 1)
}

.avatar-1,
.avatar-2,
.avatar-3,
.avatar-4 {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.avatar-2 {
  bottom: 5.8vh;
}

.avatar-3 {
  bottom: 11.6vh;
}

.avatar-4 {
  bottom: 17.5vh;
}
</style>
