<template>
  <div class="uppercase text-2xl text-gray font-600 mb-2">{{title}}</div>
</template>

<script>
export default {
  name: "HtNavTitle",
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>
