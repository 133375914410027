<template>
  <div id="ht-card" class="h-80vh w-full">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "HtCard"
}
</script>

<style lang="scss" scoped>
#ht-card {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #EAEDF3;
  overflow: hidden;
}
</style>
