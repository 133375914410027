<template>
  <div class="">
    <div class="grid gap-4" style="grid-template-columns: min-content 1fr">
      <div class="place-self-center">
        <inline-svg :src="require('@/assets/images/pin.svg')" class="w-4 h-6"/>
      </div>
      <div>
        <div class="text-gray whitespace-nowrap text-xl">
          {{Location}}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "HtEventLocation",
  components: {
    InlineSvg
  },
  props: {
    location: {
      type: String,
      required: true
    }
  },
  computed:{
    Location(){
      return this.location
    }
  }
}
</script>

<style scoped>

</style>
