<template>
  <div class="text-4xl font-700 mb-3"> {{ title }}</div>
</template>

<script>
export default {
  name: "HtCardTitle",
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>
