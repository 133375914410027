<template>
  <div>
    <div class="grid gap-4" style="grid-template-columns: min-content 1fr">
      <div class="place-self-center">
        <inline-svg :src="require('@/assets/images/time.svg')" class="w-4 h-4"/>
      </div>
      <div class="text-gray whitespace-nowrap text-xl">
        <span>{{ StartDate }} - {{ EndDate }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import InlineSvg from "vue-inline-svg";

export default {
  name: "HtEventDate",
  props: {
    startDate: {
      type: Number,
      required: true
    },
    endDate: {
      type: Number,
      required: true
    }
  },
  components: {
    InlineSvg
  },
  computed: {
    StartDate() {
      return dayjs.unix(this.startDate).format('HH:mm')
    },
    EndDate() {
      return dayjs.unix(this.endDate).format('HH:mm')
    }
  }
}
</script>

<style scoped>

</style>
