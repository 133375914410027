<template>
  <div class="pt-15vh px-5vh">
    <ht-card>
      <div id="slide-seniority">
        <div class="place-self-center">
          <ht-personal-avatar
              :image="data.image"
              :name="data.title"
              :message="data.description"/>

          <div class="text-center">
            <div class="text-5xl font-700"> {{ $t('seniority_thanks', {name: Name})}}!</div>
            <div class="text-3xl" :style="TextStyle" v-html="Message"></div>
            <div class="text-2xl" :style="TextStyle" v-html="Function"></div>
          </div>
        </div>

        <div id="flags-left">
          <inline-svg :src="require('@/assets/images/flags_left.svg')"/>
        </div>
        <div id="flags-right">
          <inline-svg :src="require('@/assets/images/flags_right.svg')"/>
        </div>
      </div>
    </ht-card>
  </div>
</template>

<script>
import HtCard from "@/components/Elements/Card/HtCard";
import HtPersonalAvatar from "@/components/Elements/Card/HtPersonalAvatar";
import InlineSvg from "vue-inline-svg";

export default {
  name: "SlideSeniority",
  components: {
    HtCard,
    HtPersonalAvatar,
    InlineSvg
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    play: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    Name() {
      return this.data.title
    },
    Message() {
      return this.data.description
    },
    Function() {
      if(this.data.profile) {
        return this.data.profile.function;
      }
      return '';
    },
    TextStyle() {
      return {
        'color': this.$store.getters.getHexColor,
        'text-shadow': '0 2px 4px rgba(0,0,0,0.10)'
      }
    }
  },
  methods: {
    setSVGColor() {
      const element = document.querySelector('#color-filter feColorMatrix')
      element.setAttribute('values', this.$store.getters.getColorMatrix)
    }
  },
  mounted() {
    this.setSVGColor()
  }
}
</script>

<style lang="scss" scoped>
#slide-seniority{
  display: grid;
  height: 100%;
  width: 100%;
  position: relative;
}

#flags-left, #flags-right {
  position: absolute;
}

#flags-left {
  top: 0;
  left: 0;
  width: 40vw;
}

#flags-right {
  top: 0;
  right: 0;
  width: 40vw;
}
</style>
