<template>
  <div class="pt-10vh">
    <figure class="w-full h-90vh bg-black">
      <transition-group name="fade">
        <img :key="`image_${index}`" v-for="(image, index) in Images" :src="image" alt=""
             class="object-contain object-center w-full h-full" v-show="index === current_image">
      </transition-group>
    </figure>
  </div>
</template>

<script>
import * as Mixins from '@/mixins/mixins'

export default {
  name: "SlideFullScreenImageGallery",

  mixins: [
    Mixins.SlidePropsMixin
  ],

  data() {
    return {
      amount_of_images: 0,
      current_image: 0,
      intervalHolder: null
    }
  },

  watch: {
    Play: {
      handler: function (val) {
        if (val) {
          this.intervalHolder = setInterval(() => {
            this.nextImage()
          }, 5000)
        } else {
          if (this.intervalHolder) {
            clearInterval(this.intervalHolder)
          }
        }
      }
    }
  },

  computed: {
    Play() {
      return this.$store.getters.getPlay && this.activeSlide === this.slideId
    },

    Images() {
      if (this.data.images) return this.data.images
      return []
    }
  },

  methods: {
    nextImage() {
      if (this.amount_of_images === 0 || this.amount_of_images === 1) return

      if (this.current_image + 1 < this.amount_of_images) {
        this.current_image++
      } else {
        this.current_image = 0
      }
    }
  },


  created() {
    this.amount_of_images = this.data.images.length
  },

  beforeDestroy() {

  }

}
</script>
