<template>
  <div id="ht-progressbar" class="h-1vh bg-background">
    <div id="bar" class="h-1vh w-full" :class="{'play': play}" :style="BarStyle"></div>
  </div>
</template>

<script>
export default {
  name: "HtProgressBar",

  props: {
    play: {
      type: Boolean
    },

    duration: {
      type: Number,
      default: 60
    }
  },

  computed: {
    RgbColors() {
      return this.$store.getters.getRGBColors
    },

    BarStyle() {
      return {
        'background-image':
            `linear-gradient(to right,
            rgba(${this.RgbColors.red},${this.RgbColors.green},${this.RgbColors.blue}, 1),
            rgba(${this.RgbColors.red},${this.RgbColors.green},${this.RgbColors.blue}, .4),
            rgba(${this.RgbColors.red},${this.RgbColors.green},${this.RgbColors.blue}, 1))`,
        'animation-duration': `${this.duration}s`
      }
    },
  }
}
</script>

<style lang="scss">
#ht-progressbar {
  background-color: #FBFBFD;
}

#bar {
  border-bottom-right-radius: 2vw;
  border-top-right-radius: 2vw;
  position: absolute;
  right: 100vw;
}

#bar.play {
  animation-name: progress-bar;
  animation-timing-function: linear;
  transition: all;
  transition-duration: 1s;
}

@keyframes progress-bar {
  from {
    right: 100vw;
  }
  to {
    right: 0;
  }
}
</style>
