<template>
  <div id="ht-header" class="bg-white w-screen h-9vh px-5vh shadow">
    <div id="logo">
      <img :src="Logo" alt="">
    </div>
    <div id="clock">
      <HtClock></HtClock>
    </div>
  </div>
</template>

<script>
import HtClock from "@/components/Elements/Header/HtClock";

export default {
  name: "HtHeader",
  components: {
    HtClock
  },
  computed: {
    Logo() {
      return this.$store.getters.getLogo
    }
  }
}
</script>

<style lang="scss" scoped>
#ht-header {
  display: grid;
  grid-template-columns: 1fr min-content;

  #logo {
    max-height: 7vh;
    align-self: center;
  }

  #logo img {
    object-fit: contain;
    max-height: 7vh;
  }

  #clock {
    align-self: center;
  }
}
</style>
