export const SlidePropsMixin ={
    props:{
        data: {
            type: Object,
            required: true,
        },

        activeSlide: {
            type: Number,
            required: true
        },

        slideId:{
            type: Number,
            required: true
        },

        duration:{
            type: Number,
            default: 60
        }
    }
}
