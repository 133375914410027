<template>
  <div>
    <div class="flex justify-center mb-8">
      <div id="solid-border" :style="OuterBorderStyle">
        <div id="image-container">
          <img :src="Image" alt="">
        </div>
        <div id="textured-border">
          <svg viewBox="0 0 473 473" version="1.1" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>Oval</title>
            <defs>
              <circle id="path-1" cx="255.6" cy="255.6" r="217.8"></circle>
              <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="-18" y="-18"
                    width="471.6" height="471.6">
                <rect x="19.8" y="19.8" width="471.6" height="471.6" fill="white"></rect>
                <use xlink:href="#path-1" fill="black"></use>
              </mask>
            </defs>
            <g id="Marlux-kiosk" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
               stroke-dasharray="4.5,6.3">
              <g id="kiosk---verjaardag" transform="translate(-722.000000, -225.000000)"
                 :stroke="$store.getters.getHexColor" stroke-width="36">
                <g id="Group-6" transform="translate(60.000000, 145.000000)">
                  <g id="Group-7" transform="translate(643.000000, 61.000000)">
                    <use id="Oval" mask="url(#mask-2)" xlink:href="#path-1"></use>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

export default {
  name: "HtPersonalAvatar",
  props: {
    image: {
      type: String,
      required: true
    }
  },
  computed: {
    Image() {
      return this.image ?? 'https://picsum.photos/200/300'
    },
    OuterBorderStyle() {
      return {
        'border-color': this.$store.getters.getHexColor
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$solid-border-with: 23vw;

#solid-border {
  border-radius: 9999px;
  border: 0.5vw solid;
  width: $solid-border-with;
  height: $solid-border-with;
  position: relative;
}

#textured-border {
  border-radius: 9999px;
  position: absolute;
  width: calc(#{$solid-border-with} - 2vw) ;
  height: calc(#{$solid-border-with} - 2vw) ;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:after {
    content: ' ';
    border-radius: 9999px;
    position: absolute;
    width: 19vw;
    height: 19vw;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }
}

#image-container {
  border-radius: 9999px;
  position: absolute;
  width: calc(#{$solid-border-with} - 5vw);
  height: calc(#{$solid-border-with} - 5vw);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;

  img {
    width: calc(#{$solid-border-with} - 5vw);
    height: calc(#{$solid-border-with} - 5vw);
    object-fit: cover;
    border-radius: 99999px;
  }
}
</style>
