<template>
  <div class="flex items-center mr-4 space-x-4">
    <div class="w-3.75vh h-3.75vh">
      <inline-svg :src="require('@/assets/images/comments.svg')" class="w-3.75vh h-3.75vh" />
    </div>
    <div class="text-4xl text-gray font-600">{{ amount }}</div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: "HtComments",
  props: {
    amount: {
      type: Number,
      required: true
    }
  },
  components: {
    InlineSvg
  }
}
</script>

<style scoped>

</style>
