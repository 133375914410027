<template>
  <div class="pt-15vh px-5vh">
    <ht-card>
      <div id="slide-event">
        <section id="container">
          <div id="sidebar">
            <ht-event-sidebar :participants="Participants" :date="EventStartDate"/>
          </div>

          <div id="content-container">
            <ht-nav-title :title="$t('event')" v-once/>

            <ht-card-title :title="Title" v-once/>

            <div>
              <div class="grid grid-cols-1 gap-2 mb-8">
                <ht-event-date :start-date="EventStartDate" :end-date="EventEndDate" v-once/>
                <ht-event-location :location="EventLocation" v-once/>
              </div>
            </div>

            <div id="description" class="flex-1" v-html="Description" v-once></div>

            <div class="flex-none">
              <ht-card-footer :likes="LikesCount" :comments="CommentsCount" :has-likes="false" v-once/>
            </div>
          </div>
        </section>

        <section>
          <ht-image-container
              :images="Images"
              :play="Play"
              :duration="duration"
          />
        </section>
      </div>
    </ht-card>
  </div>
</template>

<script>
import HtCard from "@/components/Elements/Card/HtCard";
import HtImageContainer from "@/components/Elements/HtImageContainer";
import HtCardFooter from "@/components/Elements/Card/HtCardFooter";
import HtNavTitle from "@/components/Elements/Card/HtNavTitle";
import HtCardTitle from "@/components/Elements/Card/HtCardTitle";
import HtEventSidebar from "@/components/Elements/Card/Event/HtEventSidebar";
import HtEventDate from "@/components/Elements/Card/Event/HtEventDate";
import HtEventLocation from "@/components/Elements/Card/Event/HtEventLocation";
import * as Mixins from '@/mixins/mixins'

export default {
  name: "SlideEvent",
  components: {
    HtCard,
    HtImageContainer,
    HtNavTitle,
    HtEventSidebar,
    HtCardTitle,
    HtCardFooter,
    HtEventDate,
    HtEventLocation
  },

  mixins: [
    Mixins.CommentsCountMixin,
    Mixins.LikesCountMixin,
    Mixins.ImagesMixin,
    Mixins.SlidePropsMixin
  ],
  computed: {
    Play() {
      return this.$store.getters.getPlay && this.activeSlide === this.slideId
    },
    Title() {
      return this.data.title
    },
    Description() {
      return this.data.description;
    },
    Participants() {
      return this.data.participants
    },
    EventStartDate() {
      return this.data.start_date
    },
    Images() {
      return [this.data.image]
    },
    EventEndDate() {
      return this.data.end_date
    },
    EventLocation() {
      return this.data.location
    }
  }
}
</script>

<style lang="scss" scoped>
#slide-event {
  display: grid;
  grid-template-columns: 55% 45%;
  height: 100%;
  gap: 1vw;
}

#sidebar {
  padding: 2vw 0 2vw 0;
}

#description {
  overflow: hidden;
  position: relative;

  &:after {
    content: " ";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $fade-height;
    background: rgb(255, 255, 255);
    background: $fade-gradient;
  }
}

#container {
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 1vw;
  height: $content-container-height;
}

#content-container {
  padding: $card-padding;
  display: flex;
  flex-direction: column;
  height: $content-container-height;
}
</style>
