<template>
  <div>
    <div id="svg-color-container" style="position: absolute">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           viewBox="0 0 0 0">
        <defs>
          <filter id="color-filter">
            <feColorMatrix in="SourceGraphic" color-interpolation-filters="sRGB" type="matrix"
                           values="0 0 0 0 0.341176 0 0 0 0 0.713725 0 0 0 0 0.835294 0 0 0 0.400000 0"></feColorMatrix>
          </filter>
        </defs>
      </svg>
    </div>

    <header class="fixed top-0 z-50">
      <ht-header v-once></ht-header>
      <ht-progress-bar :play="timerIsPlaying" :duration="duration"/>
    </header>

    <transition name="fade">
      <main v-if="!isLoading">
        <div :id="item.slide_id" :key="`item_${item.slide_id}`" v-for="item in resources" class="h-100vh">
          <component
            :is="item.type"
            :active-slide="currentSlide"
            :slide-id="item.slide_id"
            :data="item.data"
            @ended="nextSlide"
            :duration="duration"
          />
        </div>
      </main>
    </transition>
  </div>
</template>

<script>
import HtHeader from "@/components/Elements/Header/HtHeader";
import HtProgressBar from "@/components/Elements/HtProgressBar";
import {EVERY_MINUTE} from "@/helpers/helpers";
import {EVERY_HOUR} from "@/helpers/helpers";
import * as APIService from '@/services/APIService'

import SlideFeed from "@/components/Slides/SlideFeed";
import SlideEvent from "@/components/Slides/SlideEvent";
import SlideBirthday from "@/components/Slides/SlideBirthday";
import SlideSeniority from "@/components/Slides/SlideSeniority";
import SlideSpotlight from "@/components/Slides/SlideSpotlight";
import SlideYoutubevideo from "@/components/Slides/SlideYoutubevideo";
import SlideFullScreenImageGallery from "@/components/Slides/SlideFullScreenImageGallery";

const TIMEOUT = EVERY_MINUTE / 4;
const REFRESH = EVERY_HOUR * 4;
const RELOAD = EVERY_HOUR * 24;

export default {
  name: "Main",
  components: {
    HtHeader,
    'birthday': SlideBirthday,
    'feed': SlideFeed,
    'event': SlideEvent,
    'seniority': SlideSeniority,
    'spotlight': SlideSpotlight,
    'youtube': SlideYoutubevideo,
    'imagegallery': SlideFullScreenImageGallery,
    HtProgressBar,
  },
  data() {
    return {
      resources: [],
      slideCounter: 0,
      currentSlide: 1,
      timerIsPlaying: false,
      isLoading: true,
      timer: null,
      updateResources: false,
      reloadPage: false,
      duration: 15
    }
  }
  ,
  computed: {
    Play() {
      return this.$store.getters.getPlay;
    },
    CurrentSlide() {
      return this.resources[this.currentSlide - 1]
    },
    ResourcesLength() {
      return this.resources.length
    },
    IsLastSlide() {
      return this.currentSlide === this.ResourcesLength
    }
  },
  methods: {
    async fetchData() {
      this.reset();

      const updateData = this.updateData();
      await updateData.then((response) => {

        this.$store.dispatch('setConfig', response.data.config)
        this.$store.dispatch('setTribe', response.data.tribe)
        this.$store.dispatch('setHexColor', response.data.tribe.primary_color);

        this.resources = this.mapItems(response.data.items)

        this.$nextTick(() => {
          const slide = this.findSlide(this.currentSlide.toString());
          if (slide) this.scrollIntoView(slide);

          this.setUpdateResourcesTimer();
          this.setIsLoading(false)
          this.play()
        })

      })
    },
    reset() {
      this.setIsLoading(true)
      this.setTimerIsPlaying(false)
      this.setCurrentSlideNumber(1)
      this.slideCounter = 0
    },
    updateData() {
      return APIService.updateData(this.$store.getters.getKey)
    },

    mapItems(items) {
      return items.map((item) => {

        item = this.checkForFullScreenImageGallery(item)
        item = this.checkIfYoutubeVideo(item)

        return {
          slide_id: this.getSlideId(),
          ...item
        }
      })
    },

    checkForFullScreenImageGallery(item) {

      if (item.type === 'feed') {
        if (item.data.has_description === false && item.data.images.length !== 0) {
          item.type = 'imagegallery'
        }
      }

      return item;
    },

    checkIfYoutubeVideo(item) {
      if (item.data.video) {
        item.type = 'youtube'
      }

      return item
    },

    setUpdateResourcesTimer() {
      this.updateResources = false
      setTimeout(() => {
        this.updateResources = true
      }, REFRESH)
    },

    setIsLoading(status) {
      this.isLoading = status
    },

    play() {
      if (this.CurrentSlide) {
        if (this.CurrentSlide.type === 'youtube') {
          setTimeout(() => {
            this.$store.dispatch('setPlay', true)
          }, 1000)
        }

        if (this.CurrentSlide.type !== 'youtube') {
          setTimeout(() => {
            this.setTimer(TIMEOUT)
            this.setTimerIsPlaying(true)
            this.$store.dispatch('setPlay', true)
          }, 1000)
        }
      }
    },

    nextSlide() {
      this.setTimerIsPlaying(false)
      this.$store.dispatch('setPlay', false)

      if (this.IsLastSlide && this.reloadPage) {
        location.reload()
        return
      }

      if (this.IsLastSlide && this.updateResources) {
        this.fetchData()
        return
      }

      if (this.IsLastSlide) {
        this.goToFirstSlide()
        return;
      }


      let nextSlideNumber = ++this.currentSlide
      const slide = this.findSlide(nextSlideNumber.toString())
      this.scrollIntoView(slide)
      this.play()
    },
    goToFirstSlide() {
      this.reset()

      setTimeout(() => {
        const slide = this.findSlide(this.currentSlide.toString());
        this.scrollIntoView(slide)

        setTimeout(() => {
          this.setIsLoading(false);
          this.play()
        }, 500)

      }, 500)
    },

    scrollIntoView(slide) {
      this.$nextTick(() => {
        slide.scrollIntoView({behavior: "smooth"})
      })
    },

    findSlide(slideID) {
      return document.getElementById(slideID)
    },
    setCurrentSlideNumber(value) {
      this.currentSlide = value
    },
    setTimerIsPlaying(status) {
      this.timerIsPlaying = status
    },
    getSlideId() {
      return ++this.slideCounter;
    },

    setTimer(timeout) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.nextSlide()
      }, timeout)
    },
  },

  mounted() {
    this.fetchData()
  },

  created() {
    setTimeout(() => {
      this.reloadPage = true
    }, RELOAD)
  }
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
